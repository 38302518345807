<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("jobRiskAssessments") }}</h1>
      <br>
      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="jobRiskAssessments">
  
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
  
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW -->
            <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">Ajouter</span>
            </div>
        
          </div>
  
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ jobRiskAssessments.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : jobRiskAssessments.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
  
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=40">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=60">
                <span>60</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=80">
                <span>80</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
  
        <template slot="thead">
          <vs-th sort-key="journey">{{ $t("journey") }}</vs-th>
          <vs-th sort-key="riskDetails">{{ $t("riskDetails") }}</vs-th>
          <vs-th sort-key="nokiaValidation">{{ $t("nokiaValidation") }}</vs-th>
          <!-- <vs-th sort-key="created_at">{{ $t("created_at") }}</vs-th>
          <vs-th sort-key="updated_at">{{ $t("updated_at") }}</vs-th> -->
          <vs-th>Action</vs-th>
        </template>
  
        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.journey.purpose}}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.riskDetails}}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ validation_object[tr.nokiaValidation]}}</p>
            </vs-td>
           <!--  <vs-td>
              <p class="product-name font-medium truncate">{{ tr.created_at|date_time}}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.updated_at | date_time}}</p>
            </vs-td> -->
            <vs-td class="whitespace-no-wrap">
              <div class="flex">
               <!--  <vx-tooltip  text="Voir" color="#28C76F">
                  <feather-icon style="color: #28C76F " icon="ImageIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"   @click="openLightbox" @click.stop="Show_image(tr)" color="warring" type="border" class="mr-2" />
                </vx-tooltip> -->
                <vx-tooltip text="Voir" color="primary">
                  <feather-icon style="color: #0627bb" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="mr-2" @click="ShowData(tr)"/>
                </vx-tooltip>
                <vx-tooltip text="Modifier" color="warning">
                  <feather-icon style="color: #FF9F43" icon="EditIcon" svgClasses="w-5 h-5 hover:text-warning stroke-current" class="mr-2" @click="editData(tr)"/>
                </vx-tooltip>
                <vx-tooltip text="Supprimer" color="danger">
                  <feather-icon @click="deleteData(tr.id)" style="color: red" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="mr-2"/>
                </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
          </tbody>
          
        </template>
      </vs-table>

      <vs-popup
      v-if="activePrompt1" 
      fullscreen
        class="calendar-event-dialog"
        title="Ajouter une évaluation de risque d'emploi"
        :active.sync="activePrompt1">
        <div class="clearfix">

          <div class="vx-row">
     
          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("journey") }}<b style="color: #ff6141"></b></p>
            <vs-select
                    collapse-chips 
                    :closeOnSelect="false"
                    v-model="journey"
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    autocomplete
                    name="journey"
                    class="w-full"
                >
            <vs-select-item :key="item1" :value="item1.id" :text="item1.purpose" v-for="item1 in journeys" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('journey')">{{ errors.first("journey") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("initialRisk") }}<b style="color: #ff6141"></b></p>
            <v-select
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="initialRisk"
            :reduce="rep => rep.key"
            class="w-full" 
            label="name"
            v-model="initialRisk"
            :options="initialRisks">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('initialRisk')">{{ errors.first("initialRisk") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("initialRiskSeverity") }}<b style="color: #ff6141"></b></p>
            <v-select
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="initialRiskSeverity"
            :reduce="rep => rep.key"
            class="w-full" 
            label="name"
            v-model="initialRiskSeverity"
            :options="evaluations">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('initialRiskSeverity')">{{ errors.first("initialRiskSeverity") }}</span>
          </div>
         
          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("initialRiskProbability") }}<b style="color: #ff6141"></b></p>
               <v-select
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    name="initialRiskProbability"
                    :reduce="rep => rep.key"
                    class="w-full" 
                    label="name"
                    v-model="initialRiskProbability"
                    :options="evaluations">
              </v-select>
          <span class="text-danger text-sm" v-show="errors.has('initialRiskProbability')">{{ errors.first("initialRiskProbability") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("residualRisk") }}<b style="color: #ff6141"></b></p>
            <v-select
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    name="residualRisk"
                    :reduce="rep => rep.key"
                    class="w-full" 
                    label="name"
                    v-model="residualRisk"
                    :options="evaluations">
              </v-select>
          <span class="text-danger text-sm" v-show="errors.has('residualRisk')">{{ errors.first("residualRisk") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("residualRiskSeverity") }}<b style="color: #ff6141"></b></p>
            <v-select
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    name="residualRiskSeverity"
                    :reduce="rep => rep.key"
                    class="w-full" 
                    label="name"
                    v-model="residualRiskSeverity"
                    :options="evaluations">
              </v-select>
          <span class="text-danger text-sm" v-show="errors.has('residualRiskSeverity')">{{ errors.first("residualRiskSeverity") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("residualRiskProbability") }}<b style="color: #ff6141"></b></p>
            <v-select
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    name="residualRiskProbability"
                    :reduce="rep => rep.key"
                    class="w-full" 
                    label="name"
                    v-model="residualRiskProbability"
                    :options="evaluations">
              </v-select>
          <span class="text-danger text-sm" v-show="errors.has('residualRiskProbability')">{{ errors.first("residualRiskProbability") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("riskDetails") }}<b style="color: #ff6141"></b></p>
            <vs-textarea
                  name="riskDetails"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  v-model="riskDetails"
                  rows="3"
                  class="w-full"
                />
          <span class="text-danger text-sm" v-show="errors.has('riskDetails')">{{ errors.first("riskDetails") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("comapasatoryMeasures") }}<b style="color: #ff6141"></b></p>
            <vs-textarea
                  name="comapasatoryMeasures"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  v-model="comapasatoryMeasures"
                  rows="3"
                  class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('comapasatoryMeasures')">{{ errors.first("comapasatoryMeasures") }}</span>

          </div>
         
          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
          <span><b style="color:orange">{{ name_file1_3.length }}{{ ' ' }}Fichier(s)</b></span>
              <input  name="name_file1_3" type="file"  class="hidden" ref="uploadImgInput1_3" @change="updateCurrImg1_3" accept=".pdf" multiple >
              <span class="text-danger text-sm" v-show="errors.has('name_file1_3')">{{ errors.first("name_file1_3") }}</span>
              <vs-button @click="$refs.uploadImgInput1_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>

        </div>

        <vx-card  class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">NOKIA</span>
          </div>
          <vs-divider />
        </div>
      </div>
      <div class="vx-row ">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("nokiaComment") }}<b style="color: #ff6141"></b></p>
            <vs-textarea
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="nokiaComment"
                  v-model="nokiaComment"
                  rows="3"
                  class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('nokiaComment')">{{ errors.first("nokiaComment") }}</span>

          </div>
          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("nokiaValidation") }}<b style="color: #ff6141"></b></p>
            <vs-checkbox 
              v-model="nokiaValidation"
              name="nokiaValidation"
              class="w-full">
            </vs-checkbox>
          </div>
      </div>
     
   
        </vx-card>

        </div>
          <vs-button color="success"  class="float-right mt-4" @click="submitData()" >Valider</vs-button>
        </div>
      </vs-popup>
      
      <vs-popup
        v-if="activePrompt2" 
        fullscreen
        class="calendar-event-dialog"
        title="Modifier l'évaluation de risque d'emploi"
        :active.sync="activePrompt2">
        <div class="clearfix">

        <div class="vx-row">
            <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("journey") }}<b style="color: #ff6141"></b></p>
            <vs-select
                    collapse-chips 
                    :closeOnSelect="false"
                    v-model="journey"
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    autocomplete
                    name="journey"
                    class="w-full"
                >
            <vs-select-item :key="item1" :value="item1.id" :text="item1.purpose" v-for="item1 in journeys" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('journey')">{{ errors.first("journey") }}</span>
            </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("initialRisk") }}<b style="color: #ff6141"></b></p>
            <v-select
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="initialRisk"
            :reduce="rep => rep.key"
            class="w-full" 
            label="name"
            v-model="initialRisk"
            :options="initialRisks">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('initialRisk')">{{ errors.first("initialRisk") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("initialRiskSeverity") }}<b style="color: #ff6141"></b></p>
            <v-select
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="initialRiskSeverity"
            :reduce="rep => rep.key"
            class="w-full" 
            label="name"
            v-model="initialRiskSeverity"
            :options="evaluations">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('initialRiskSeverity')">{{ errors.first("initialRiskSeverity") }}</span>
          </div>
         
          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("initialRiskProbability") }}<b style="color: #ff6141"></b></p>
               <v-select
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    name="initialRiskProbability"
                    :reduce="rep => rep.key"
                    class="w-full" 
                    label="name"
                    v-model="initialRiskProbability"
                    :options="evaluations">
              </v-select>
          <span class="text-danger text-sm" v-show="errors.has('initialRiskProbability')">{{ errors.first("initialRiskProbability") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("residualRisk") }}<b style="color: #ff6141"></b></p>
            <v-select
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    name="residualRisk"
                    :reduce="rep => rep.key"
                    class="w-full" 
                    label="name"
                    v-model="residualRisk"
                    :options="evaluations">
              </v-select>
          <span class="text-danger text-sm" v-show="errors.has('residualRisk')">{{ errors.first("residualRisk") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("residualRiskSeverity") }}<b style="color: #ff6141"></b></p>
            <v-select
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    name="residualRiskSeverity"
                    :reduce="rep => rep.key"
                    class="w-full" 
                    label="name"
                    v-model="residualRiskSeverity"
                    :options="evaluations">
              </v-select>
          <span class="text-danger text-sm" v-show="errors.has('residualRiskSeverity')">{{ errors.first("residualRiskSeverity") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("residualRiskProbability") }}<b style="color: #ff6141"></b></p>
            <v-select
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    name="residualRiskProbability"
                    :reduce="rep => rep.key"
                    class="w-full" 
                    label="name"
                    v-model="residualRiskProbability"
                    :options="evaluations">
              </v-select>
          <span class="text-danger text-sm" v-show="errors.has('residualRiskProbability')">{{ errors.first("residualRiskProbability") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("riskDetails") }}<b style="color: #ff6141"></b></p>
            <vs-textarea
                  name="riskDetails"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  v-model="riskDetails"
                  rows="3"
                  class="w-full"
                />
          <span class="text-danger text-sm" v-show="errors.has('riskDetails')">{{ errors.first("riskDetails") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("comapasatoryMeasures") }}<b style="color: #ff6141"></b></p>
            <vs-textarea
                  name="comapasatoryMeasures"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  v-model="comapasatoryMeasures"
                  rows="3"
                  class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('comapasatoryMeasures')">{{ errors.first("comapasatoryMeasures") }}</span>

          </div>
         
          <div class="vx-col p-6 sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
          <span><b style="color:orange">{{ name_file1_4.length }}{{ ' ' }}Fichier(s)</b></span>
              <input  name="name_file1_4" type="file"  class="hidden" ref="uploadImgInput1_4" @change="updateCurrImg1_4" accept=".pdf" multiple >
              <span class="text-danger text-sm" v-show="errors.has('name_file1_4')">{{ errors.first("name_file1_4") }}</span>
              <vs-button @click="$refs.uploadImgInput1_4.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Ajouter</vs-button>

        </div>
        <vx-card  class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">NOKIA</span>
          </div>
          <vs-divider />
        </div>
      </div>
      <div class="vx-row ">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("nokiaComment") }}<b style="color: #ff6141"></b></p>
            <vs-textarea
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="nokiaComment"
                  v-model="nokiaComment"
                  rows="3"
                  class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('nokiaComment')">{{ errors.first("nokiaComment") }}</span>

          </div>
          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("nokiaValidation") }}<b style="color: #ff6141"></b></p>
            <vs-checkbox 
              v-model="nokiaValidation"
              name="nokiaValidation"
              class="w-full">
            </vs-checkbox>
          </div>
      </div>
     
   
        </vx-card>

        <vx-card  class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Document(s)</span>
          </div>
          <vs-divider />
        </div>
      </div>
      <div class="vx-row ">
    </div>
      <div class="vx-row ">
    </div>
    <div class="vx-row ">
      <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_computed">
      <template slot="header">
       
      </template>
      <template slot="thead">
        <vs-th hidden  >N°</vs-th>
        <vs-th>Nom du fichier</vs-th>
        <vs-th>Actions</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
        
          <vs-td hidden :data="data[indextr].id">
            {{data[indextr].id}}
          </vs-td>
          <vs-td :data="data[indextr].sites">
            {{data[indextr].url}}
          </vs-td>           
          <vs-td class="whitespace-no-wrap">
            <div class="flex">
              <vs-button @click.stop="openfiles(data[indextr].url)" color="primary" size="small" icon-pack="feather" type="filled" icon="icon-eye">Voir</vs-button>    
              <vs-button @click.stop="deleteProduit(data[indextr].id)"  color="danger" size="small" icon-pack="feather" type="filled" class="ml-2" icon="icon-delete">Supprimer</vs-button>

            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    </div>
        </vx-card>


        </div>
        <vs-button color="success"  class="float-right mt-4" @click="submitData()" >Valider</vs-button>
        </div>
      </vs-popup>
 
      <vs-popup
      v-if="activePrompt3" 
      fullscreen
        class="calendar-event-dialog"
        title="Détails de l'évaluation de risque d'emploi"
        :active.sync="activePrompt3">
        <div class="clearfix">

        <div class="vx-row">
            <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("journey") }}<b style="color: #ff6141"></b></p>
            <vs-select
                disabled
                :closeOnSelect="false"
                    v-model="journey"
                    autocomplete
                    name="journey"
                    class="w-full"
                >
            <vs-select-item :key="item1" :value="item1.id" :text="item1.purpose" v-for="item1 in journeys" /></vs-select>
            </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("initialRisk") }}<b style="color: #ff6141"></b></p>
            <v-select
            disabled
            name="initialRisk"
            :reduce="rep => rep.key"
            class="w-full" 
            label="name"
            v-model="initialRisk"
            :options="initialRisks">
          </v-select>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("initialRiskSeverity") }}<b style="color: #ff6141"></b></p>
            <v-select
            disabled
            name="initialRiskSeverity"
            :reduce="rep => rep.key"
            class="w-full" 
            label="name"
            v-model="initialRiskSeverity"
            :options="evaluations">
          </v-select>
          </div>
         
          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("initialRiskProbability") }}<b style="color: #ff6141"></b></p>
               <v-select
                    disabled
                    name="initialRiskProbability"
                    :reduce="rep => rep.key"
                    class="w-full" 
                    label="name"
                    v-model="initialRiskProbability"
                    :options="evaluations">
              </v-select>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("residualRisk") }}<b style="color: #ff6141"></b></p>
            <v-select
                    disabled
                    name="residualRisk"
                    :reduce="rep => rep.key"
                    class="w-full" 
                    label="name"
                    v-model="residualRisk"
                    :options="evaluations">
              </v-select>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("residualRiskSeverity") }}<b style="color: #ff6141"></b></p>
            <v-select
                    disabled
                    name="residualRiskSeverity"
                    :reduce="rep => rep.key"
                    class="w-full" 
                    label="name"
                    v-model="residualRiskSeverity"
                    :options="evaluations">
              </v-select>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("residualRiskProbability") }}<b style="color: #ff6141"></b></p>
            <v-select
                    disabled
                    name="residualRiskProbability"
                    :reduce="rep => rep.key"
                    class="w-full" 
                    label="name"
                    v-model="residualRiskProbability"
                    :options="evaluations">
              </v-select>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("riskDetails") }}<b style="color: #ff6141"></b></p>
            <vs-textarea
                  disabled
                  name="riskDetails"
                  v-model="riskDetails"
                  rows="3"
                  class="w-full"
                />
          <span class="text-danger text-sm" v-show="errors.has('riskDetails')">{{ errors.first("riskDetails") }}</span>
          </div>

          <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
            <p>{{ $t("comapasatoryMeasures") }}<b style="color: #ff6141"></b></p>
            <vs-textarea
                 disabled
                  name="comapasatoryMeasures"
                  v-model="comapasatoryMeasures"
                  rows="3"
                  class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('comapasatoryMeasures')">{{ errors.first("comapasatoryMeasures") }}</span>

          </div>

        <vx-card  class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">NOKIA</span>
          </div>
          <vs-divider />
        </div>
      </div>
      <div class="vx-row ">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("nokiaComment") }}<b style="color: #ff6141"></b></p>
            <vs-textarea
                 disabled
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="nokiaComment"
                  v-model="nokiaComment"
                  rows="3"
                  class="w-full"
                />

          </div>
          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("nokiaValidation") }}<b style="color: #ff6141"></b></p>
            <vs-checkbox 
              disabled
              v-model="nokiaValidation"
              name="nokiaValidation"
              class="w-full">
            </vs-checkbox>
          </div>
      </div>
     
   
        </vx-card>

        <vx-card  class="mt-10" no-shadow card-border>
       <div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Document(s)</span>
          </div>
          <vs-divider />
        </div>
      </div>
      <div class="vx-row ">
    </div>
      <div class="vx-row ">
    </div>
    <div class="vx-row ">
      <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_computed">
      <template slot="header">
       
      </template>
      <template slot="thead">
        <vs-th hidden  >N°</vs-th>
        <vs-th>Nom du fichier</vs-th>
        <vs-th>Action</vs-th>

      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
        
          <vs-td hidden :data="data[indextr].id">
            {{data[indextr].id}}
          </vs-td>
          <vs-td :data="data[indextr].sites">
            {{data[indextr].url}}
          </vs-td>           
          <vs-td class="whitespace-no-wrap">
            <div class="flex">
              <vs-button @click.stop="openfiles(data[indextr].url)" color="primary" size="small" icon-pack="feather" type="filled" icon="icon-eye">Voir</vs-button>    

            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    </div>
        </vx-card>

        </div>
        <vs-button color="primary"  class="float-right mt-4" @click="quit()" >Quitter</vs-button>
        </div>
      </vs-popup>

    </div>
  </template>
<script>

import { Validator } from 'vee-validate'
const link = 'https://jra.mitigarisk.com/digira/uploads/'

const dict = {
  custom: {
    journey: {
      required: 'Le champ trajet est obligatoire'
    },
    initialRisk: {
      required: 'Le champ risque initial est obligatoire'
    },
    initialRiskSeverity: {
      required: 'Le champ gravité initiale du risque est obligatoire'
    },
    initialRiskProbability: {
      required: 'Le champ probabilité de risque initiale est obligatoire'
    },
    comapasatoryMeasures: {
      required: 'Le champ mesure compensatoire est obligatoire'
    },
    residualRisk: {
      required: 'Le champ risque résiduel est obligatoire'
    },
    residualRiskSeverity: {
      required: 'Le champ gravité du risque résiduel est obligatoire'
    },
    residualRiskProbability: {
      required: 'Le champ probabilité de risque résiduel est obligatoire'
    },
   
    nokiaComment: {
      required: 'Le champ nokia commentaire est obligatoire'
    },
    riskDetails: {
      required: 'Le champ détails du risque est obligatoire'
    }
    
  }
}
Validator.localize('fr', dict)
export default {
  data () {
    return {
      activePrompt3:false,
      activePrompt2:false,
      activePrompt1:false,
      Check_update:false,
      id:'',
      journey:'',
      initialRisk:'',
      initialRiskSeverity:'',
      initialRiskProbability:'',
      comapasatoryMeasures:'',
      residualRisk:'',
      residualRiskSeverity:'',
      residualRiskProbability:'',
      nokiaValidation:false,
      nokiaComment:'',
      riskDetails:'',

      documents:'',
      produits_computed:[],
      deleted_id:[],
      jobRiskAssessments: [],
      journeys: [],
      
      selected: [],
      validation_object:{
        true:'Oui',
        false:'Non'
      },
      initialRisks:[
        {
          key: 1,
          name: 'ZONE DE RICHESSE NATURELLE ENTRAINANT UNE CRIMINALITE'
        },
        {
          key: 2,
          name: 'MANQUE DE STRUCTURATION SUR L\'AXE ET ZONE DE DEPLOIEMENT'
        }, {
          key: 3,
          name: 'SECURITE DES AXES - ENGIN EXPLOSIF IMPROVISÉ / MINE / ATTAQUE'
        }, { 
          key: 4,
          name: 'RISQUES ROUTIERS ET PRATICABILITE DES AXES'
        }, {
          key: 5,
          name: 'CRISE SECURITAIRE DANS LES PAYS VOISINS TRANSPIRANT DANS LA ZONE DE DEPLOIEMENT'
        }, {
          key: 6,
          name: 'CRISE DES PRODUITS DE 1ère NECESSITE (carburant, Danree…)'
        }, {
          key: 7,
          name: 'EXPOSITION LEGALE'
        }, {
          key: 8,
          name: 'CORRUPTION AMBIANTE'
        }, {
          key: 9,
          name: 'PERIODE ELECTORALE ET CRISE POLITIQUE (Municipale/Législative/Sénatoriale/Présidentielle)'
        }, {
          key: 10,
          name: 'CRISE SOCIALE ET DETRESSE HUMAINE'
        }, {
          key: 11,
          name: 'MANIFESTATION SUR L\'ITINERAIRE OU ZONE DE DEPLOIEMENT'
        }, {
          key: 12,
          name: 'MOUVEMENT DJIHADISTE SUR L\'ITINERAIRE ET DANS LA ZONE D\'OPÉRATION'
        }, {
          key: 13,
          name: 'OPÉRATIONS MILITAIRES'
        }, {
          key: 14,
          name: 'ATTAQUE DE POSTES MILITAIRES'
        }, {
          key: 15,
          name: 'ATTAQUE DE VILLAGE / COMBAT ETHNIQUE'
        }, {
          key: 16,
          name: 'DESTRUCTION DES INSTALLATIONS DE COMMINICATION'
        }, {
          key: 17,
          name: 'AGRESSION PHYSIQUE'
        }, {
          key: 18,
          name: 'VOL À MAIN ARMÉE'
        }, {
          key: 19,
          name: 'VOL DE VOITURE / CARJACKING'
        }, {
          key: 20,
          name: 'MEUTRE / HOMICIDE'
        }, {
          key: 21,
          name: 'KIDNAPPING'
        }, {
          key: 22,
          name: 'FUSILLADE'
        }, {
          key: 23,
          name: 'CLIMAT / METEO'
        }, {
          key: 24,
          name: 'CATASTROPHE NATURELLE'
        }, {
          key: 25,
          name: 'RISQUES SANITAIRES'
        } 
      ],
      evaluationsObject: {
        1: 'EXTRÊME',
        2: 'CONSIDÉRABLE',
        3: 'ÉLEVÉ',
        4: 'MODÉRÉ',
        5: 'NÉGLIGEABLE'

      },
      evaluations: [
        {
          key: 1,
          name: 'EXTRÊME'
        },
        {
          key: 2,
          name: 'CONSIDÉRABLE'
        },
        {
          key: 3,
          name: 'ÉLEVÉ'
        },
        {
          key: 4,
          name: 'MODÉRÉ'
        },
        {
          key: 5,
          name: 'NÉGLIGEABLE'
        }
      ],
      initialRisks_liste:[
        {
          key: 1,
          name: 'NÉGLIGEABLE(1) X NÉGLIGEABLE(1) '
        },
        {
          key: 2,
          name: 'NÉGLIGEABLE(1) X MODÉRÉ(2)'
        }, {
          key: 3,
          name: 'NÉGLIGEABLE(1) X ÉLEVÉ(3)'
        }, { 
          key: 4,
          name: 'NÉGLIGEABLE(1) X CONSIDÉRABLE(4) '
        }, {
          key: 5,
          name: 'NÉGLIGEABLE(1) X EXTRÊME(5)'
        }, {
          key: 2,
          name: 'MODÉRÉ(2) X NÉGLIGEABLE(1)'
        }, {
          key: 4,
          name: 'MODÉRÉ(2) X MODÉRÉ(2)'
        }, {
          key: 6,
          name: 'MODÉRÉ(2) X ÉLEVÉ(3)'
        }, {
          key: 8,
          name: 'MODÉRÉ(2) X CONSIDÉRABLE(4)'
        }, {
          key: 10,
          name: 'MODÉRÉ(2) X EXTRÊME(5)'
        }, {
          key: 3,
          name: 'ÉLEVÉ(3) X NÉGLIGEABLE(1)'
        }, {
          key: 6,
          name:  'ÉLEVÉ(3) X MODÉRÉ(2)'
        }, {
          key: 9,
          name: 'ÉLEVÉ(3) X ÉLEVÉ(3)'
        }, {
          key: 12,
          name: 'ÉLEVÉ(3) X CONSIDÉRABLE(4)'
        }, {
          key: 15,
          name: 'ÉLEVÉ(3) X EXTRÊME(5)'
        }, {
          key: 4,
          name: 'CONSIDÉRABLE(4) X NÉGLIGEABLE(1)'
        }, {
          key: 8,
          name: 'CONSIDÉRABLE(4) X MODÉRÉ(2)'
        }, {
          key: 12,
          name: 'CONSIDÉRABLE(4) X ÉLEVÉ(3)'
        }, {
          key: 16,
          name: 'CONSIDÉRABLE(4) X CONSIDÉRABLE(4)'
        }, {
          key: 20,
          name: 'CONSIDÉRABLE(4) X EXTRÊME(5)'
        }, {
          key: 5,
          name: 'EXTRÊME(5) X NÉGLIGEABLE(1)'
        }, {
          key: 10,
          name: 'EXTRÊME(5) X MODÉRÉ(2)'
        }, {
          key: 15,
          name: 'EXTRÊME(5) X ÉLEVÉ(3)'
        }, {
          key: 20,
          name: 'EXTRÊME(5) X CONSIDÉRABLE(4)'
        }, {
          key: 25,
          name: 'EXTRÊME(5) X EXTRÊME(5)'
        } 
      ],
      dataImg1_3:[],
      name_file1_3:[],
      dataImg1_4:[],
      name_file1_4:[],
      isMounted: false,
      itemsPerPage: 20
     
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.jobRiskAssessments.length
    }
  },
 
  methods: {

    REMOVE_ITEM_PRODUIT (produits, produitId) {
      const ItemIndex = produits.findIndex((p) => p.id === produitId)
      produits.splice(ItemIndex, 1)
    },

    deleteProduit (id) {
      this.REMOVE_ITEM_PRODUIT(this.produits_computed, id)
      this.deleted_id.push(id)
      this.$vs.notify({
        color:'success',
        icon:'delete',
        text:'Fichier supprimé de la liste',
        position:'top-right'
      })
    
    },
    openfiles (file) {
      window.open(`${ link  }${file}`, '_blank')
    },

    updateCurrImg1_3 (input) {
      if (input.target.files && input.target.files.length >0) {
        for (let i = 0; i < input.target.files.length; i++) {
          this.dataImg1_3.push(input.target.files[i])
          this.name_file1_3.push(input.target.files[i].name)
        }
       
        
      }
    },

    updateCurrImg1_4 (input) {
      if (input.target.files && input.target.files.length >0) {
        for (let i = 0; i < input.target.files.length; i++) {
          this.dataImg1_4.push(input.target.files[i])
          this.name_file1_4.push(input.target.files[i].name)
        }
       
        
      }
    },
    addNewData () {
      this.$validator.reset()
      this.intialise()
      this.Check_update = false
      this.activePrompt1 = true
    },
    quit () {
      this.activePrompt3 = false
      this.$validator.reset()
      this.intialise()
    },
    ShowData (data) {
      this.intialise()
      this.id = data.id
      this.journey = data.journey.id
      this.initialRisk = data.initialRisk
      this.initialRiskSeverity = data.initialRiskSeverity
      this.initialRiskProbability = data.initialRiskProbability
      this.comapasatoryMeasures = data.comapasatoryMeasures
      this.residualRisk = data.residualRisk
      this.residualRiskSeverity = data.residualRiskSeverity
      this.residualRiskProbability = data.residualRiskProbability
      this.nokiaValidation = data.nokiaValidation
      this.nokiaComment = data.nokiaComment
      this.riskDetails = data.riskDetails
      this.documents = ''
      if (data.jobRiskAssessmentFiles.length) {
        for (let i = 0; i < data.jobRiskAssessmentFiles.length; i++) {
          this.bene_object = 
            {
              'id': data.jobRiskAssessmentFiles[i].id,
              'url':(data.jobRiskAssessmentFiles[i].url).substring(17)
            }
          
          this.produits_computed.push(this.bene_object)
        }
      }
      this.activePrompt3 = true
    },
    editData (data) {
      this.intialise()
      this.id = data.id
      this.journey = data.journey.id
      this.initialRisk = data.initialRisk
      this.initialRiskSeverity = data.initialRiskSeverity
      this.initialRiskProbability = data.initialRiskProbability
      this.comapasatoryMeasures = data.comapasatoryMeasures
      this.residualRisk = data.residualRisk
      this.residualRiskSeverity = data.residualRiskSeverity
      this.residualRiskProbability = data.residualRiskProbability
      this.nokiaValidation = data.nokiaValidation
      this.nokiaComment = data.nokiaComment
      this.riskDetails = data.riskDetails
      if (data.jobRiskAssessmentFiles.length) {
        for (let i = 0; i < data.jobRiskAssessmentFiles.length; i++) {
          this.bene_object = 
            {
              'id': data.jobRiskAssessmentFiles[i].id,
              'url':(data.jobRiskAssessmentFiles[i].url).substring(17)
            }
          
          this.produits_computed.push(this.bene_object)
        }
      }
      this.documents = ''
      this.Check_update = true
      this.activePrompt2 = true
    },
    intialise () {
      this.id = ''
      this.journey = ''
      this.initialRisk = ''
      this.initialRiskSeverity = ''
      this.initialRiskProbability = ''
      this.comapasatoryMeasures = ''
      this.residualRisk = ''
      this.residualRiskSeverity = ''
      this.residualRiskProbability = ''
      this.nokiaValidation = ''
      this.nokiaComment = ''
      this.riskDetails = ''
      this.documents = ''
      this.produits_computed = []
      this.deleted_id = []
      this.dataImg1_3 = []
      this.name_file1_3 = []
      this.dataImg1_4 = []
      this.name_file1_4 = []
      this.Check_update = false
      this.activePrompt1 = false
      this.activePrompt2 = false

    },
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`jobRiskAssessments/${id}/`)
            .then(response => {
              this.getAlljobRiskAssessments()
              window.getPrendTaCom.success('L\' évaluations des risques d\'emploi est supprimée avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression a échoué.' })
            })
        }
      })
  
    },
    submitData () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const formdata = new FormData()
          this.$vs.loading()
          if (this.Check_update === false) {
         
            formdata.append('journey', this.journey)
            formdata.append('initialRisk', this.initialRisk)
            formdata.append('initialRiskSeverity', this.initialRiskSeverity)
            formdata.append('initialRiskProbability', this.initialRiskProbability)
            formdata.append('comapasatoryMeasures', this.comapasatoryMeasures)
            formdata.append('residualRisk', this.residualRisk)
            formdata.append('residualRiskSeverity', this.residualRiskSeverity)
            formdata.append('residualRiskProbability', this.residualRiskProbability)
            formdata.append('nokiaValidation', this.nokiaValidation)
            formdata.append('nokiaComment', this.nokiaComment)
            formdata.append('riskDetails', this.riskDetails)
            
            if (this.dataImg1_3.length) {
              for (let i = 0; i < this.dataImg1_3.length; i++) {
                formdata.append('files', this.dataImg1_3[i], this.name_file1_3[i])
              }
            }
      
            //documents:this.documents
            
          } else if (this.Check_update === true) {
            formdata.append('journey', this.journey)
            formdata.append('initialRisk', this.initialRisk)
            formdata.append('initialRiskSeverity', this.initialRiskSeverity)
            formdata.append('initialRiskProbability', this.initialRiskProbability)
            formdata.append('comapasatoryMeasures', this.comapasatoryMeasures)
            formdata.append('residualRisk', this.residualRisk)
            formdata.append('residualRiskSeverity', this.residualRiskSeverity)
            formdata.append('residualRiskProbability', this.residualRiskProbability)
            formdata.append('nokiaValidation', this.nokiaValidation)
            formdata.append('nokiaComment', this.nokiaComment)
            formdata.append('riskDetails', this.riskDetails)
            
            if (this.dataImg1_4.length) {
              for (let i = 0; i < this.dataImg1_4.length; i++) {
                formdata.append('files_add', this.dataImg1_4[i], this.name_file1_4[i])
              }
            }

            if (this.deleted_id.length) {
              for (let i = 0; i < this.deleted_id.length; i++) {
                formdata.append('files_remove', this.deleted_id[i])
              }
            }
            //documents:this.documents
            
          }
          let url = 'jobRiskAssessments/'
          let methods = 'post'
          const message = {
            error: 'Votre enregistrement a échoué.',
            success: 'L\' évaluations des risques d\'emploi  est enrégistrée avec succès.'
          }
          if (this.id) {
            url += `${this.id}/`
            methods = 'put'
            message.success = 'L\'évaluations des risques d\'emploi est modifiée avec succès.'
  
          }
  
          this.$http[methods](url, formdata)
            .then((response) => {
              window.jobRiskAssessments.getAlljobRiskAssessments()
              window.getPrendTaCom.success(message.success, response)
              this.intialise()
            })
            .catch((error) => {
              const clefs = Object.keys(error.response.data)
              for (let i = 0; i < clefs.length; i++) {
                const item = clefs[i]
                if (item === 'name') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ nom d\'équipe obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'tasks') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ tâche est obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'staffs') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ personnel esr obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'vehicles') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ nom véhicule es obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'liftingEquipments') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Équiment de levage',
                    color: 'warning',
                    position: 'top-center'
                  })
                }
              }
              this.$vs.notify({
                time: 8000,
                title: 'ENREGISTREMENT',
                text: error.response.data.error,
                color: 'danger',
                position: 'top-right'
              })
              this.$vs.loading.close()
            })
        }
      })
        
    },
    getAllJourney () {
      this.$vs.loading()
      this.$http.get('journeys/')
        .then((response) => {
          this.journeys = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAlljobRiskAssessments () {
      this.$vs.loading()
      this.$http.get('jobRiskAssessments/')
        .then((response) => {
          this.jobRiskAssessments = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  created () {
    window.jobRiskAssessments = this
    this.getAlljobRiskAssessments()
    this.getAllJourney()

  },
  mounted () {
    this.isMounted = true
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
  
      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  <style scoped>
  
  </style>
  