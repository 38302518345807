import { render, staticRenderFns } from "./DataListjobRiskAssessments.vue?vue&type=template&id=7f514a90&scoped=true&"
import script from "./DataListjobRiskAssessments.vue?vue&type=script&lang=js&"
export * from "./DataListjobRiskAssessments.vue?vue&type=script&lang=js&"
import style0 from "./DataListjobRiskAssessments.vue?vue&type=style&index=0&id=7f514a90&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f514a90",
  null
  
)

export default component.exports